import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import { 
  TextField, Button, Typography, Card, CardActions,
  Container, CardContent, LinearProgress
} from '@mui/material';

import { useMutation, useQuery, gql } from '@apollo/client';
import adminClient from 'api/apollo-client';

import UploadButton from 'components/shared/form/UploadButton';

import Page from 'shared/AppPage';

const propTypes = {

}

const defaultProps = {

}

const DerivedPurchaseOrdersPriorityFile = (props) => {
  const [inputFileData, setInputFileData] = useState({
    base64: null,
    mimeType: null,
    filename: null,
  });

  const [errors, setErrors] = useState([]);

  const [sendNotification, notificationProps] = useMuiNotification();

  const { data: appData, loading: loadingGet, refetch } = useQuery(APP_GET, { client: adminClient });

  const [appUpdate, { loading: loadingUpdate }] = useMutation(APP_UPDATE, { 
    client: adminClient,
    onCompleted: (data) => {
      // 
    },
    onError: {
      // 
    }
  });

  const handleSubmit = () => {
    appUpdate({
      variables: {
        input: {
          derivedPurchaseOrdersPriorityFile: inputFileData
        },
      }
    })
  }

  const handleFileChange = (file) => {
    setErrors([]);
    const filename = file.name;
    const reader = new FileReader();
    reader.onload = (e) => { // Get the base64 string and send it to the API
      const readerResult = reader.result; // Format is: data:<media_type>;base64,<base64_string>. i.e. "data:image/jpeg;base64,/9jxxx..."
      const parts = readerResult.split(";base64,");
      const mimeType = parts[0].split(":")[1]; // i.e. "image/jpeg"
      const base64 = parts[1]; // Base64 string

      setInputFileData({ base64, mimeType, filename });
    }
    reader.readAsDataURL(file); // This will trigger the onload event
  }

  console.log(appData);

  const canSubmit = inputFileData.base64 !== null;
  const loading = loadingGet || loadingUpdate;

  return (
    <Page>
      <AppNotification {...notificationProps} />

      <Container>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Archivo de prioridad de supplier
            </Typography>

            <Typography color="GrayText">
              Explicación sobre archivo de prioridades...
            </Typography>

            <div>
              <UploadButton
                onUpload={ handleFileChange }
                helpText="Solo se aceptan archivos .xlsx y .xls"
              />
              <Button
                className='mt12'
                fullWidth variant="contained"
                color='primary'
                onClick={ handleSubmit }
                disabled={ loading || !canSubmit }>
                Cargar
              </Button>
            </div>

            <CardActions>
              <Button
                href='/samples/derived_purchase_orders_priority_file_sample.xlsx'
                color='info'
              >
                Descargar archivo de muestra
              </Button>

              { appData && appData.app.derivedPurchaseOrdersPriorityFile && (
                <Button
                  href={ appData.app.derivedPurchaseOrdersPriorityFile.url }
                  color='secondary'
                >
                  Descargar archivo actual
                </Button>
              )}
            </CardActions>
            { errors.map((error, index) => (
              <Typography key={ index } color="error">
                { error.message }
              </Typography>
            ))}
          </CardContent>
        </Card>

        { loading && <LinearProgress color='primary' /> }
      </Container>
    </Page>
  )
};

DerivedPurchaseOrdersPriorityFile.propTypes = propTypes;
DerivedPurchaseOrdersPriorityFile.defaultProps = defaultProps;

const APP_GET = gql`
  query {
    app {
      id
      derivedPurchaseOrdersPriorityFile {
        url
        identifier
        contentType
      }
    }
  }
`;

const APP_UPDATE = gql`
  mutation($input: AppInput!) {
    appUpdate(input: $input) {
      app{
        id
      }
      errors{
        field
        message
      }
    }
  }
`;

export default DerivedPurchaseOrdersPriorityFile;